import { Fragment, useRef } from "react";

import c from "classnames";
import { Image } from "react-datocms";
import slugify from "react-slugify";
import Link from "next/link";

import { ImageAndTextSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { Icon } from "@/components/FormElements/Icon";
import { ButtonVariants } from "@/types/inputFields";
import { Video } from "@/components/Video";
import { useUserDataStore } from "@/store/UserDataStore";
import { Accordion } from "@/components/FormElements/Accordion";
import { BackButton } from "@/components/BackButton";

import Styles from "./imageAndText.module.scss";

type Props = {
	content: ImageAndTextSection;
	children?: React.ReactNode;
	reverseOnMobile?: boolean;
	backBtn?: boolean;
};

export const ImageAndText = ({
	content,
	children,
	reverseOnMobile = false,
	backBtn = false,
}: Props) => {
	const videoRef = useRef(null);
	const [user] = useUserDataStore((state) => [state.user]);

	const NonSplitHeading = content.heading?.[0]?.sizing || "h2";

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.imageTextCont, {
				[Styles["background-" + slugify(content.backgroundColour)]]:
					content.backgroundColour,
				[Styles.textOnLeft]: content.textOnLeft,
				[Styles.reverseOnMobile]: reverseOnMobile,
				[Styles["sideIcons-" + slugify(content.sideIcons)]]: content.sideIcons,
			})}
		>
			{content.backgroundImage && (
				<div
					className={c(Styles.backgroundImage, {
						[Styles.zigzagBorder]: content.imageZigzag,
						// @ts-ignore
						[Styles.videoPaused]: videoRef?.current?.paused,
						[Styles.video]: content.videoUrl,
					})}
				>
					{content.videoUrl ? (
						<Video
							src={content.videoUrl}
							poster={
								(content.backgroundImage?.responsiveImage?.src as string) ||
								undefined
							}
							id={content.heading?.[0]?.headingLine1 || ""}
							autoplay={false}
						/>
					) : (
						<Image data={content.backgroundImage.responsiveImage} />
					)}
					{!!content.imageOverlay && content.imageOverlay}
				</div>
			)}
			<div className={c("container", Styles.imageText)}>
				<div
					className={c(Styles.content, {
						[Styles["headingSmall"]]:
							content.heading?.[0]?.headingSize === "small",
						[Styles.videoContent]: content.videoUrl,
					})}
				>
					{backBtn && <BackButton showOnMobile={true} noSpacing={true} />}
					{content?.heading?.[0]?.split && (
						<>
							{content?.heading?.[0]?.headingLine1 && (
								<SplitHeading
									heirarchy={content.heading[0].sizing || "h2"}
									line1={content.heading[0].headingLine1.replace(
										"%%%",
										user.voucher?.type === "fixed"
											? "£" + (user.voucher.amount / 100).toFixed(0)
											: user.voucher?.amount + "%"
									)}
									line2={
										content.heading[0].headingLine2?.replace(
											"%%%",
											user.voucher?.type === "fixed"
												? "£" + (user.voucher.amount / 100).toFixed(0)
												: user.voucher?.amount + "%"
										) || ""
									}
									line3={
										content.heading[0].headingLine3?.replace(
											"%%%",
											user.voucher?.type === "fixed"
												? "£" + (user.voucher.amount / 100).toFixed(0)
												: user.voucher?.amount + "%"
										) || ""
									}
									colour={content.heading[0].secondaryLineColour}
									subtitle={content.heading[0].subtitle}
									link={content.heading[0].headingLink}
								/>
							)}
						</>
					)}
					{content.heading &&
						!content?.heading?.[0]?.split &&
						content.heading[0].headingLink && (
							<Link href={content.heading[0].headingLink}>
								<a>
									<NonSplitHeading>
										{content?.heading?.[0]?.headingLine1.replace(
											"%%%",
											user.voucher?.type === "fixed"
												? "£" + (user.voucher.amount / 100).toFixed(0)
												: user.voucher?.amount + "%"
										)}
									</NonSplitHeading>
								</a>
							</Link>
						)}
					{content.heading &&
						!content?.heading?.[0]?.split &&
						!content.heading[0].headingLink && (
							<NonSplitHeading>
								{content?.heading?.[0]?.headingLine1.replace(
									"%%%",
									user.voucher?.type === "fixed"
										? "£" + (user.voucher.amount / 100).toFixed(0)
										: user.voucher?.amount + "%"
								)}
							</NonSplitHeading>
						)}
					{content?.content && (
						<span
							className={c(
								{ paraLarge: content.heading?.[0]?.headingSize !== "small" },
								{ paraMed: content.heading?.[0]?.headingSize === "small" },
								Styles.contentText
							)}
							dangerouslySetInnerHTML={{ __html: content.content }}
						/>
					)}
					{content?.iconPoints?.[0]?.icon &&
						content.iconPoints?.map((iconPoint, key) => (
							<div className={Styles.iconPoint} key={key}>
								<Icon
									icon={iconPoint.icon}
									height="48"
									width="48"
									noFill={true}
								/>
								<span>{iconPoint.text}</span>
							</div>
						))}
					{content?.stats?.[0]?.number &&
						content.stats?.map((stat, key) => (
							<div className={c(Styles.stat)} key={key}>
								<span className={c("h1", Styles.statNumber)}>
									{stat.number}
								</span>
								<span className={c("h3", Styles.statDescription)}>
									{stat.description}
								</span>
							</div>
						))}

					{content?.children && content?.children}

					{content?.accordions?.map((accordion, key) => (
						<Accordion
							key={key}
							leftAlign
							heading={<h4 className="heading--inline">{accordion.title}</h4>}
						>
							<span
								dangerouslySetInnerHTML={{ __html: accordion.content || "" }}
							/>
						</Accordion>
					))}

					{content?.button && content?.button?.length > 0 && (
						<div className={Styles.buttons}>
							{content?.button.map(({ link, buttonType, text }) =>
								!text ? (
									<></>
								) : (
									<Fragment key={text}>
										<Button
											url={link}
											width="relative"
											sizing="large"
											variant={
												buttonType === "Secondary"
													? ButtonVariants.clearLight
													: buttonType === "Text"
													? ButtonVariants.ghostLight
													: ButtonVariants.default
											}
										>
											{text.replace(
												"%%%",
												user.voucher?.type === "fixed"
													? "£" + (user.voucher.amount / 100).toFixed(0)
													: user.voucher?.amount + "%"
											)}
										</Button>
									</Fragment>
								)
							)}
						</div>
					)}
					{children && children}
				</div>
			</div>
		</section>
	);
};
